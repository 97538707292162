import React from 'react';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';

import Autocomplete, {
  AutocompleteChangeReason
} from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import { AutocompleteItem, Suggestion } from './AutocompleteItem';
import { PopperWrapper } from './PopperWrapper';

// this is a static reference which aids in memoization
const EMPTY_LIST: Suggestion[] = [];
const ACCEPTABLE_REASON = ['selectOption', 'removeOption', 'clear'];

export const formatInputValue = (
  value?: Suggestion | Suggestion[],
  multiple?: boolean
): SuggestionType => {
  if (multiple && !value) {
    return [];
  } else if (multiple && value) {
    return !isArray(value) ? [value] : value;
  } else {
    return value || undefined;
  }
};

export const formatSuggestion = (
  suggestion: SuggestionChangeType | null
): Suggestion | undefined => {
  if (suggestion) {
    return typeof suggestion === 'string'
      ? { id: suggestion, label: suggestion }
      : suggestion;
  }

  return undefined;
};

export type SuggestionChangeType = Suggestion | string | undefined;
export type SuggestionType = Suggestion | Suggestion[] | undefined;

export type AutocompleteFieldProps = {
  className?: string;
  anchorRef?: React.MutableRefObject<HTMLElement | null>;
  value?: SuggestionType;
  label?: string;
  multiple?: boolean;
  suggestions?: Suggestion[];
  onBlur?: () => void;
  onChange?: (suggestion: SuggestionType) => void;
  onFocus?: () => void;
  disabled?: boolean;
  autoFocus?: boolean;
  autoSelect?: boolean;
  groupBy?: (suggestion: Suggestion) => string;
  helperText?: string;
  error?: boolean;
  loading?: boolean;
};

const useAutocompleteValue = (
  valueProp: SuggestionType,
  multiple?: boolean
) => {
  const state = React.useState<SuggestionType>(multiple ? [] : undefined);
  const [, setValue] = state;

  const formatValueProp = React.useCallback(
    (val?: SuggestionType) => {
      setValue(formatInputValue(val, multiple));
    },
    [multiple, setValue]
  );

  React.useEffect(() => {
    formatValueProp(valueProp);
  }, [valueProp, formatValueProp]);

  return state;
};

export const AutocompleteField = React.forwardRef<
  HTMLInputElement,
  AutocompleteFieldProps
>((props, ref) => {
  const {
    className,
    anchorRef,
    autoFocus,
    autoSelect,
    disabled = false,
    error,
    groupBy,
    helperText,
    label,
    multiple,
    onBlur,
    onChange,
    onFocus,
    suggestions,
    value: valueProp,
    loading
  } = props;

  const [hasInput, setHasInput] = React.useState<boolean>(false);
  const [value, setValue] = useAutocompleteValue(valueProp, multiple);

  const handleBlur = () => onBlur?.();
  const handleFocus = () => onFocus?.();

  const handleChange = (
    evt: React.ChangeEvent<unknown>,
    suggestion: SuggestionChangeType[] | SuggestionChangeType | null,
    reason: AutocompleteChangeReason
  ) => {
    if (
      ACCEPTABLE_REASON.includes(reason) ||
      (autoSelect && reason === 'blur')
    ) {
      let next: SuggestionType = undefined;

      if (isArray(suggestion)) {
        next = suggestion.reduce<Suggestion[]>((accum, suggestion) => {
          const formatted = formatSuggestion(suggestion);
          if (formatted) accum.push(formatted);
          return accum;
        }, []);
      } else {
        next = formatSuggestion(suggestion);
      }

      setValue(next);
      onChange?.(next);
    }
  };

  const handleInputChange = (evt: React.SyntheticEvent, val: string) => {
    setHasInput(Boolean(val));
  };

  return (
    <Root className={className}>
      <Autocomplete
        autoComplete
        autoSelect={hasInput && autoSelect}
        autoHighlight={hasInput && autoSelect}
        selectOnFocus
        loading={loading}
        filterSelectedOptions={multiple}
        disabled={disabled}
        multiple={multiple}
        options={suggestions || EMPTY_LIST}
        groupBy={groupBy}
        value={
          (multiple ? (value as Suggestion[]) : (value as Suggestion)) || null
        }
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onInputChange={autoSelect ? handleInputChange : undefined}
        PopperComponent={anchorRef ? PopperWrapper(anchorRef) : undefined}
        getOptionLabel={(suggestion) =>
          (typeof suggestion === 'string' ? suggestion : suggestion?.label) ||
          ''
        }
        isOptionEqualToValue={isEqual}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <AutocompleteItem
              label={option?.label}
              description={option?.description}
            />
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant='outlined'
            autoFocus={autoFocus}
            error={Boolean(error)}
            helperText={helperText}
            inputRef={ref}
            InputProps={{
              ...params.InputProps,
              margin: anchorRef ? 'dense' : 'none'
            }}
          />
        )}
      />
    </Root>
  );
});

const Root = styled('div')`
  & .MuiAutocomplete-listbox {
    max-height: 100% !important;
    flex: 1;
    border-radius: 0;
  }

  & .MuiAutocomplete-paper {
    height: 100%;
    display: flex;
    border-radius: 0;
  }
`;
